import React, { useState, useRef } from "react";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  uploadString,
} from "firebase/storage";
import { firestore, storage, auth } from "../firebase-config";
import { QRCodeCanvas } from "qrcode.react"; // Correctly importing QRCodeCanvas
import "./ImageUploader.css";
const PersonForm = () => {
  const alihsaaProvince =
    "https://firebasestorage.googleapis.com/v0/b/healthsite-f24cb.appspot.com/o/images%2FalihsaaProvinceLogo.png?alt=media&token=2ae0bae6-7824-4e19-8678-985b9b79ddf6";

  const alsharqiyaProvince =
    "https://firebasestorage.googleapis.com/v0/b/healthsite-f24cb.appspot.com/o/images%2FalsharqiyaProvinceLogo.png?alt=media&token=5c6e0e6a-e01b-4b90-b34d-291bd845f86d";

  const jiddaProvince =
    "https://firebasestorage.googleapis.com/v0/b/healthsite-f24cb.appspot.com/o/images%2FjiddaProvinceLogo.png?alt=media&token=ad78b14f-6eb3-4318-8f63-df581a633ea9";

  const [formData, setFormData] = useState({
    gov: "",
    provinceImageURL: "",
    governote: "",
    uIdInSystem: "",
    name: "",
    idNumber: "",
    sex: "",
    nationality: "",
    healthCertificateNumber: "",
    occupation: "",
    healthLicenseIssueDateHijri: "",
    healthLicenseIssueDateMiladi: "",
    healthLicenseEndDateHijri: "",
    healthLicenseEndDateMiladi: "",
    programType: "",
    endDateOfProgram: "",
    programLicenseId: "",
    facilityName: "",
    facilityNumber: "",
    licenceType: "",
    createdAt: serverTimestamp(),
  });
  const [isUploading, setIsUploading] = useState(false);
  const [formError, setFormError] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [selectionsState, setSelectionsState] = useState({
    provinceImageURL: false,
    licenceType: false,
    sex: false,
  });
  const errorStyle = {
    border: "2px solid red",
  };
  const qrRef = useRef(); // Ref for QR code canvas

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    console.log(formData.uIdInSystem.slice(0, 5));
  };

  const handleLogout = async () => {
    try {
      await auth.signOut();
      console.log("User signed out.");
      window.location.href = "/login"; // Redirect to login page after signing out
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  const handleImageChange = (e) => {
    setImageFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    setIsUploading(true);
    e.preventDefault();
    setFormError("");

    if (!imageFile) {
      setFormError("Please upload an image before submitting.");
      return setIsUploading(false);
    }
    const Ready = Object.entries(formData).every(([key, value]) => {
      if (key === "programType") return true;
      if (key === "endDateOfProgram") return true;
      return value !== "";
    });
    if (formData.licenceType === "") {
      setSelectionsState((prevData) => ({ ...prevData, licenceType: true }));
    } else {
      setSelectionsState((prevData) => ({ ...prevData, licenceType: false }));
    }
    if (formData.provinceImageURL === "") {
      setSelectionsState((prevData) => ({
        ...prevData,
        provinceImageURL: true,
      }));
    } else {
      setSelectionsState((prevData) => ({
        ...prevData,
        provinceImageURL: false,
      }));
    }
    if (formData.sex === "") {
      setSelectionsState((prevData) => ({ ...prevData, sex: true }));
    } else {
      setSelectionsState((prevData) => ({
        ...prevData,
        sex: false,
      }));
    }
    if (Ready) {
      try {
        const storageRef = ref(
          storage,
          `images/${imageFile.name + formData.uIdInSystem.slice(0, 5)}`
        );
        const uploadTask = uploadBytesResumable(storageRef, imageFile);

        uploadTask.on(
          "state_changed",
          null,
          (error) => {
            console.error("Error uploading image:", error);
            setFormError("Failed to upload image. Please try again.");
          },
          async () => {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

            // Generate the QR code image as a Data URL
            const canvas = qrRef.current.querySelector("canvas");
            if (!canvas) {
              setFormError("Failed to generate QR code. Please try again.");
              return;
            }
            const qrImage = canvas.toDataURL("image/png");

            // Upload QR code image to Firebase Storage
            const qrStorageRef = ref(
              storage,
              `qrCodes/${formData.uIdInSystem}.png`
            );
            const qrUploadTask = await uploadString(
              qrStorageRef,
              qrImage,
              "data_url"
            );
            const qrCodeImageUrl = await getDownloadURL(qrUploadTask.ref);

            // Save form data to Firestore, including image and QR code URLs
            await addDoc(collection(firestore, "people"), {
              ...formData,
              imageUrl: downloadURL,
              qrCodeImageUrl: qrCodeImageUrl,
            });

            alert("Information saved successfully!");
            setFormData({
              gov: "",
              provinceImageURL: "",
              governote: "",
              uIdInSystem: "",
              name: "",
              idNumber: "",
              sex: "",
              nationality: "",
              healthCertificateNumber: "",
              occupation: "",
              healthLicenseIssueDateHijri: "",
              healthLicenseIssueDateMiladi: "",
              healthLicenseEndDateHijri: "",
              healthLicenseEndDateMiladi: "",
              programType: "",
              endDateOfProgram: "",
              programLicenseId: "",
              facilityName: "",
              facilityNumber: "",
              licenceType: "",
              createdAt: "",
            });
            setImageFile(null);
            setIsUploading(false);
          }
        );
      } catch (error) {
        console.error("Error adding document: ", error);
        setFormError("Failed to save information. Please try again.");
      }
    } else {
      setIsUploading(false);
    }
  };

  return (
    <>
      <div className="editing-navigation-Links">
        <a
          className="show-link nav-link"
          href="/management/Ehealth/admin/LicenseShower"
        >
          صفحة التعديل
        </a>
        <a
          className="show-link nav-link"
          href="/management/Ehealth/admin/createHealthLicense"
        >
          انشاء شهادة صحية
        </a>
        <button
          className="show-link"
          style={{ backgroundColor: "#bf2701" }}
          onClick={handleLogout}
        >
          تسجيل خروج
        </button>
      </div>
      <div className="form-container">
        <form className="form-real" onSubmit={handleSubmit}>
          <div>
            <div className="input-group">
              <label htmlFor="healthLicenseIssueDateHijri">
                تاريخ اصدار الشهادة الصحية هجري
              </label>
              <input
                required
                dir="rtl"
                name="healthLicenseIssueDateHijri"
                type="text"
                value={formData.healthLicenseIssueDateHijri}
                onChange={handleInputChange}
                pattern="\d{4}/\d{2}/\d{2}"
                placeholder="YYYY/MM/DD"
              />
            </div>
            <div className="input-group">
              <label htmlFor="healthLicenseIssueDateMiladi">
                تاريخ اصدار الشهادة الصحية ميلادي
              </label>
              <input
                required
                dir="rtl"
                name="healthLicenseIssueDateMiladi"
                type="text"
                value={formData.healthLicenseIssueDateMiladi}
                onChange={handleInputChange}
                pattern="\d{4}/\d{2}/\d{2}"
                placeholder="YYYY/MM/DD"
              />
            </div>
            <div className="input-group">
              <label htmlFor="healthLicenseEndDateHijri">
                تاريخ نهاية الشهادة الصحية هجري
              </label>
              <input
                required
                dir="rtl"
                name="healthLicenseEndDateHijri"
                type="text"
                value={formData.healthLicenseEndDateHijri}
                onChange={handleInputChange}
                pattern="\d{4}/\d{2}/\d{2}"
                placeholder="YYYY/MM/DD"
              />
            </div>
            <div className="input-group">
              <label htmlFor="healthLicenseEndDateMiladi">
                تاريخ نهاية الشهادة الصحية ميلادي
              </label>
              <input
                required
                dir="rtl"
                name="healthLicenseEndDateMiladi"
                type="text"
                value={formData.healthLicenseEndDateMiladi}
                onChange={handleInputChange}
                pattern="\d{4}/\d{2}/\d{2}"
                placeholder="YYYY/MM/DD"
              />
            </div>
            <div className="input-group">
              <label htmlFor="programType">نوع البرنامج التثقيفي</label>
              <input
                dir="rtl"
                name="programType"
                type="text"
                value={formData.programType}
                onChange={handleInputChange}
              />
            </div>
            <div className="input-group">
              <label htmlFor="endDateOfProgram">
                تاريخ انتهاء البرنامج التثقيفي
              </label>
              <input
                dir="rtl"
                name="endDateOfProgram"
                type="text"
                value={formData.endDateOfProgram}
                onChange={handleInputChange}
                pattern="\d{4}/\d{2}/\d{2}"
                placeholder="YYYY/MM/DD"
              />
            </div>
            <div className="input-group">
              <label htmlFor="programLicenseId">رقم الرخصة</label>
              <input
                required
                dir="rtl"
                name="programLicenseId"
                type="text"
                value={formData.programLicenseId}
                onChange={handleInputChange}
              />
            </div>
            <div className="input-group">
              <label htmlFor="facilityName">اسم المنشآة</label>
              <input
                required
                dir="rtl"
                name="facilityName"
                type="text"
                value={formData.facilityName}
                onChange={handleInputChange}
              />
            </div>
            <div className="input-group">
              <label htmlFor="facilityNumber">رقم المنشأة</label>
              <input
                required
                dir="rtl"
                name="facilityNumber"
                type="text"
                value={formData.facilityNumber}
                onChange={handleInputChange}
              />
            </div>
            <div className="input-group" style={{ gridColumn: "span 2" }}>
              <label htmlFor="image">Upload Image</label>
              <input
                required
                dir="rtl"
                type="file"
                onChange={handleImageChange}
              />
            </div>
            {/* QR Code Canvas */}
            <div ref={qrRef}>
              <QRCodeCanvas
                value={`https://baledyapp.com/Eservices/HealthIssue/PrintedLicenses/?uuid=${formData.uIdInSystem}`}
                size={128}
              />
            </div>
          </div>
          <div>
            <div className="input-group">
              <label htmlFor="gov">الامانة</label>
              <input
                required
                dir="rtl"
                name="gov"
                type="text"
                value={formData.gov}
                onChange={handleInputChange}
              />
            </div>
            <div className="input-group">
              <label htmlFor="provinceSelect">صورة الامانة</label>
              <select
                id="provinceSelect"
                required
                name="provinceImageURL"
                placeholder="hello"
                defaultValue={"Choose One"}
                onChange={handleInputChange}
                style={selectionsState.provinceImageURL ? errorStyle : {}}
              >
                <option disabled value={"Choose One"}>
                  اختر الامانة
                </option>

                <option value={alihsaaProvince}>امانة منطقة الاحساء</option>
                <option value={alsharqiyaProvince}>امانة منطقة الشرقية</option>
                <option value={jiddaProvince}>امانة جدة</option>
              </select>
            </div>
            <div className="input-group">
              <label htmlFor="governote">البلدية</label>
              <input
                required
                dir="rtl"
                name="governote"
                type="text"
                value={formData.governote}
                onChange={handleInputChange}
              />
            </div>
            <div className="input-group">
              <label htmlFor="uIdInSystem">UID رقم الهوية في النظام</label>
              <input
                required
                dir="rtl"
                name="uIdInSystem"
                type="text"
                value={formData.uIdInSystem}
                onChange={handleInputChange}
              />
            </div>
            <div className="input-group">
              <label htmlFor="name">الاسم</label>
              <input
                required
                dir="rtl"
                name="name"
                type="text"
                value={formData.name}
                onChange={handleInputChange}
              />
            </div>
            <div className="input-group">
              <label htmlFor="idNumber">رقم الهوية</label>
              <input
                required
                dir="rtl"
                name="idNumber"
                type="text"
                value={formData.idNumber}
                onChange={handleInputChange}
              />
            </div>
            <div className="input-group">
              <label htmlFor="sex">الجنس</label>
              <select
                required
                defaultValue={"Choose One"}
                name="sex"
                onChange={handleInputChange}
                style={selectionsState.sex ? errorStyle : {}}
              >
                <option disabled value={"Choose One"}>
                  اختر الجنس
                </option>
                <option value="ذكر">ذكر</option>
                <option value="انثى">انثى</option>
              </select>
            </div>
            <div className="input-group">
              <label htmlFor="nationality">الجنسية</label>
              <input
                required
                dir="rtl"
                name="nationality"
                type="text"
                value={formData.nationality}
                onChange={handleInputChange}
              />
            </div>
            <div className="input-group">
              <label htmlFor="healthCertificateNumber">
                رقم الشهادة الصحية
              </label>
              <input
                required
                dir="rtl"
                name="healthCertificateNumber"
                type="text"
                value={formData.healthCertificateNumber}
                onChange={handleInputChange}
              />
            </div>
            <div className="input-group">
              <label htmlFor="occupation">المهنة</label>
              <input
                required
                dir="rtl"
                name="occupation"
                type="text"
                value={formData.occupation}
                onChange={handleInputChange}
              />
            </div>
            <div className="input-group">
              <label htmlFor="licenceType">نوع الشهادة الصحية</label>
              <select
                required
                name="licenceType"
                type="radio"
                defaultValue={"Choose One"}
                onChange={handleInputChange}
                style={selectionsState.licenceType ? errorStyle : {}}
              >
                <option disabled value={"Choose One"}>
                  اختر نوع الشهادة
                </option>
                <option value="شهادة صحية سنوية">شهادة صحية سنوية</option>
                <option value="الشهادة الصحية الموحدة">
                  الشهادة الصحية الموحدة
                </option>
              </select>
            </div>
          </div>
          {formError && (
            <p className="error" style={{ gridColumn: "span 2" }}>
              {formError}
            </p>
          )}
          {!isUploading ? (
            <button type="submit">انشاء</button>
          ) : (
            <button
              disabled
              style={{ backgroundColor: "#4134", color: "#fff" }}
            >
              جاري التحميل...
            </button>
          )}
        </form>
      </div>
    </>
  );
};

export default PersonForm;
