import  { useEffect } from 'react';

const RedirectToExternalSite = () => {
  useEffect(() => {
    // Redirect to the specified URL
    window.location.href = 'https://apps.balady.gov.sa';
  }, []);

  return null; // Since the user is redirected, there's no need to render anything
};

export default RedirectToExternalSite;
