// firebase-config.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAuth } from 'firebase/auth';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyABiq-yj99pvmIGf0mBwh-KPSppmBS6nFU",
  authDomain: "healthsite-f24cb.firebaseapp.com",
  databaseURL: "https://healthsite-f24cb-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "healthsite-f24cb",
  storageBucket: "healthsite-f24cb.appspot.com",
  messagingSenderId: "599282905418",
  appId: "1:599282905418:web:ec9bf6127711ef385791b3",
  measurementId: "G-NHW6CDQMGB"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
// Initialize Firestore and Storage
const firestore = getFirestore(app);
const storage = getStorage(app);

export { firestore, storage ,app,auth };
