// Modal.js
import React from "react";

const Modal = ({ isOpen, onClose, onConfirm, person }) => {
  if (!isOpen) return null;

  return (
    <div style={modalOverlayStyle}>
      <div style={modalContentStyle}>
        <h2>Confirm Deletion</h2>
        <img src={person.imageUrl} alt={person.name} style={imageStyle} />
        <p>
          هل انت متاكد من حذف{" "}
          <span style={{ color: "red" }}>{person.name}</span>?
        </p>
        <button onClick={onConfirm} style={confirmButtonStyle}>
          Delete
        </button>
        <button onClick={onClose} style={cancelButtonStyle}>
          Cancel
        </button>
      </div>
    </div>
  );
};

// Styles for modal
const modalOverlayStyle = {
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const modalContentStyle = {
  backgroundColor: "white",
  padding: "20px",
  borderRadius: "8px",
  maxWidth: "400px",
  textAlign: "center",
};

const imageStyle = {
  width: "100px",
  height: "100px",
  borderRadius: "50%",
};

const confirmButtonStyle = {
  marginRight: "10px",
  color: "#fff",
  backgroundColor: "red",
};

const cancelButtonStyle = {
  color: "#fff",
  backgroundColor: "#777",
};

export default Modal;
