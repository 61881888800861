import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "./index.css";
import App from "./App";
import LicenseShower from "./licenses/LicenseShower";
import reportWebVitals from "./reportWebVitals";
import PersonDetail from "./licenses/LicenseToBeShown";
import CreateLicense from "./LicenceCreate/CreateLicense";
import LoginPage from "./ManagementPages/LoginPage";
import { AuthProvider } from "./Components/AuthContext"; // Import AuthProvider
import ProtectedRoute from "./Components/ProtectedRoute"; // Import ProtectedRoute
import PdfComponent from "./testingPrintFinal/PdfComponent";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <AuthProvider>
      <Routes>
        <Route path="/" element={<App />} />
        <Route
          path="/Eservices/HealthIssue/PrintedLicenses"
          element={<PersonDetail />}
        />
        <Route path="/login" element={<LoginPage />} />{" "}
        {/* Add the login route */}
        <Route
          path="/management/Ehealth/admin/createHealthLicense"
          element={
            <ProtectedRoute>
              <CreateLicense />
            </ProtectedRoute>
          }
        />
        <Route
          path="/management/Ehealth/admin/LicenseShower"
          element={
            <ProtectedRoute>
              <LicenseShower />
            </ProtectedRoute>
          }
        />
        <Route
          path="/management/Ehealth/admin/PrintableLicense"
          element={
            <ProtectedRoute>
              <PdfComponent />
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </AuthProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
